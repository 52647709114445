import React, { useState } from "react";
import Modal from "../Modal";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import Button from "../buttons/Button";
import PrimaryButton from "../buttons/PrimaryButton";
import getPrefix from "../../util/getPrefix";

export default ({ publicQuizId, publicEventId, onClose }) => {
  const [linkCopied, setLinkCopied] = useState(false);

  const handleCopyToClipboard = () => {
    setLinkCopied(true);
  };

  const getNoun = () => {
    if (publicEventId) {
      return "event";
    }
    if (publicQuizId) {
      return "quiz";
    }
  };

  const getLink = () => {
    if (publicEventId) {
      return `${getPrefix()}/join-event?eventId=${publicEventId}`;
    }
    if (publicQuizId) {
      return `${getPrefix()}/join?quizId=${publicQuizId}`;
    }
    return null;
  };

  return (
    <Modal
      title="Invitation Link"
      text={`This is the link players can use to join the ${getNoun()}.`}
      text2={getLink()}
      buttons={
        <>
          <CopyToClipboard text={getLink()} onCopy={handleCopyToClipboard}>
            {linkCopied ? (
              <Button size="large">Copied</Button>
            ) : (
              <PrimaryButton size="large">Copy to Clipboard</PrimaryButton>
            )}
          </CopyToClipboard>
          <Button size="large" onClick={onClose}>
            Close
          </Button>
        </>
      }
    />
  );
};
