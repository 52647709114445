import React, { useState } from "react";
import Modal from "../Modal";
import DangerButton from "../buttons/DangerButton";
import OurButton from "../buttons/Button";
import { navigate } from "gatsby";
import { gql, useMutation } from "@apollo/client";

const RESET_EVENT = gql`
  mutation ResetEvent($publicEventId: String!) {
    resetEvent(publicEventId: $publicEventId) {
      event {
        id
      }
    }
  }
`;

export default ({ publicEventId, onClose }) => {
  const [resetEventMutation] = useMutation(RESET_EVENT);

  const [submitted, setSubmitted] = useState(false);

  const handleResetEvent = async () => {
    setSubmitted(true);
    await resetEventMutation({
      variables: {
        publicEventId,
      },
    });
    await navigate("/");
  };

  return (
    <Modal
      title="Reset Event"
      text="Are you sure you want to reset this event?"
      buttons={
        <>
          <DangerButton
            size="large"
            onClick={handleResetEvent}
            disabled={submitted}
          >
            {submitted ? "Resetting" : "Reset"}
          </DangerButton>
          <OurButton size="large" onClick={onClose}>
            Cancel
          </OurButton>
        </>
      }
    />
  );
};
