import runningInBrowser from "./runningInBrowser";

export default () => {
  if (runningInBrowser()) {
    if (window.location.href.startsWith("http://localhost")) {
      return "http://localhost:3000";
    } else if (window.location.href.startsWith("http://10.0.2.2")) {
      return "http://10.0.2.2:3000";
    } else if (
      window.location.href.startsWith("https://staging-app.hedgehogtrivia.com")
    ) {
      return "https://staging-app.hedgehogtrivia.com";
    }
  }

  return "https://app.hedgehogtrivia.com";
};
