import React from "react";
import ReactSelect from "react-select";

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderColor: "rgba(0, 0, 0, 0.33);",
    paddingTop: 5,
    paddingRight: 0,
    paddingLeft: 0,
    paddingBottom: 5,
    fontWeight: "bold",
    maxWidth: 400,
    minWidth: 300,
  }),
};

export default (props) => <ReactSelect styles={customStyles} {...props} />;
