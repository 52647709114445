import React, { useContext, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Formik, useFormikContext } from "formik";
import { navigate } from "gatsby";
import styled from "styled-components";
import ContentBox from "../components/ContentBox";
import Form from "../components/Form";
import FormField from "../components/FormField";
import Label from "../components/Label";
import Input from "../components/Input";
import FormError from "../components/FormError";
import Button from "../components/buttons/Button";
import FormHint from "../components/FormHint";
import Link from "../components/Link";
import ActionBar from "../components/OldActionBar";
import DangerButton from "../components/buttons/DangerButton";
import Modal from "../components/Modal";
import { Drag } from "grommet-icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import GetLinkModal from "../components/modals/GetLinkModal";
import ResetEventModal from "../components/modals/ResetEventModal";
import UserContext from "../contexts/UserContext";
import UserSelect from "./UserSelect";
import Bugsnag from "@bugsnag/js";
import { REGULAR_TEXT } from "../colors";
import DarkPageHeading from "./DarkPageHeading";

const GET_EVENT = gql`
  query GetEvent($id: ID!) {
    event: getEvent(id: $id) {
      name
      publicId
      whatToShow
      editable
      owner {
        id
        username
      }
      eventGroups {
        id
        number
        name
        hostUser {
          id
          username
        }
      }
      prototypeQuiz {
        id
        rounds {
          id
          number
          title
          questions {
            id
            number
            text
            answer
          }
        }
        tiebreaker {
          id
          text
        }
      }
    }
  }
`;

const HOST_EVENT = gql`
  mutation HostEvent($publicEventId: String!) {
    hostEvent(publicEventId: $publicEventId) {
      event {
        id
        name
      }
    }
  }
`;

const HOST_EVENT_GROUP = gql`
  mutation HostEventGroup($publicEventId: String!) {
    hostEventGroup(publicEventId: $publicEventId) {
      event {
        id
        name
      }
    }
  }
`;

const SAVE_EVENT = gql`
  mutation SaveEvent($id: ID, $name: String!, $ownerId: ID) {
    saveEvent(id: $id, name: $name, ownerId: $ownerId) {
      event {
        id
        name
        publicId
      }
    }
  }
`;

const REORDER_ROUNDS = gql`
  mutation ReorderRounds($quizId: ID!, $roundId: ID!, $newNumber: Int!) {
    reorderRounds(quizId: $quizId, roundId: $roundId, newNumber: $newNumber) {
      quiz {
        id
      }
    }
  }
`;

const DELETE_EVENT = gql`
  mutation DeleteEvent($id: ID!) {
    deleteEvent(id: $id) {
      success
    }
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 400px;
`;

const EventStartedMessage = styled.div`
  font-size: 18px;
  color: red;
  margin-bottom: 20px;
`;

const UneditableValue = styled.div`
  color: ${REGULAR_TEXT};
  font-weight: bold;
`;

const RoundHeading = styled.div`
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 15px;
`;

const EventGroupHeading = styled(RoundHeading)``;

const RoundListing = styled.div`
  margin-bottom: 10px;
  svg {
    opacity: 0;
  }

  ${({ disabled }) =>
    !disabled &&
    `
    :hover svg {
      opacity: 1;
    }
  `}
`;

const EventGroupListing = styled(RoundListing)``;

const TiebreakerListing = styled(RoundListing)`
  margin-left: 20px;
`;

const DragWrapper = styled.span`
  padding: 15px 5px;

  :hover {
    cursor: grab;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
`;

function EventEditor({
  eventId,
  publicEventId,
  editable,
  ownerId,
  ownerUsername,
  eventGroups,
  prototypeQuizId,
  rounds,
  tiebreaker,
  originalValues,
  hosting,
  hostingGroup,
  isStarted,
  onHostEvent,
  onHostEventGroup,
  onReorderRounds,
  onDelete,
}) {
  const { currentUser } = useContext(UserContext);

  const isSuperuser = currentUser && currentUser.isSuperuser;

  const {
    values,
    touched,
    errors,
    handleChange,
    submitForm,
    isSubmitting,
    setFieldValue,
  } = useFormikContext();

  const [showGetLinkModal, setShowGetLinkModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState();
  const [isDeleting, setIsDeleting] = useState();

  const isChanged = () => {
    if (
      values.eventName !== originalValues.name ||
      values.ownerId !== ownerId
    ) {
      return true;
    }
    return false;
  };

  const handleGroupDragEnd = async () => {
    // const handleGroupDragEnd = async (result) => {
    // const roundId = result.draggableId;
    // const newNumber = result.destination.index + 1;
    //
    // await onReorderRounds(roundId, newNumber);
  };

  const handleDragEnd = async (result) => {
    const roundId = result.draggableId;
    const newNumber = result.destination.index + 1;

    await onReorderRounds(roundId, newNumber);
  };

  const [showResetModal, setShowResetModal] = useState();

  const handleGetLinkClick = () => {
    setShowGetLinkModal(true);
  };

  const handleCloseGetLink = () => {
    setShowGetLinkModal(false);
  };

  const handleResetClick = () => {
    setShowResetModal(true);
  };

  const handleCancelReset = () => {
    setShowResetModal(false);
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    await onDelete();
  };

  return (
    <ContentBox direction="column">
      <DarkPageHeading data-cy="page-heading">
        {!eventId ? "Create an Event" : "Edit Event"}
      </DarkPageHeading>
      <FormWrapper>
        <Form>
          {isStarted && (
            <EventStartedMessage>
              Event is not editable since it has already started.
            </EventStartedMessage>
          )}
          <FormField>
            <Label htmlFor="eventName">Event Name</Label>
            {editable && (
              <>
                <Input
                  type="text"
                  name="eventName"
                  autoComplete="off"
                  disabled={isStarted}
                  value={values.eventName}
                  onChange={handleChange}
                />
                {errors.eventName && touched.eventName && (
                  <FormError>{errors.eventName}</FormError>
                )}
              </>
            )}
            {!editable && <UneditableValue>{values.eventName}</UneditableValue>}
          </FormField>
          {publicEventId && (
            <FormField>
              <Label htmlFor="eventName">Public Event Id</Label>
              <UneditableValue>{publicEventId}</UneditableValue>
            </FormField>
          )}
          <FormField>
            <Label>Owner</Label>
            {isSuperuser && (
              <UserSelect
                name="ownerId"
                defaultValue={{ value: ownerId, label: ownerUsername }}
                onChange={(option) => setFieldValue("ownerId", option.value)}
              />
            )}
            {!isSuperuser && <UneditableValue>{ownerUsername}</UneditableValue>}
          </FormField>
          <EventGroupHeading>Groups</EventGroupHeading>
          <DragDropContext onDragEnd={handleGroupDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {eventGroups.map((group, index) => (
                    <Draggable
                      key={group.id}
                      draggableId={group.id}
                      index={index}
                      isDragDisabled={isStarted || !editable}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <EventGroupListing disabled={isStarted || !editable}>
                            <DragWrapper>
                              <Drag size="small" />
                            </DragWrapper>
                            <Link
                              key={group.id}
                              to={`/create-edit-event-group?eventId=${eventId}&number=${group.number}`}
                            >
                              Group {group.number}: {group.name} (
                              {group.hostUser.username})
                            </Link>
                          </EventGroupListing>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {editable && (
            <ButtonWrapper>
              <Link
                to={`/create-edit-event-group?eventId=${eventId}&nextNumber=${
                  eventGroups.length + 1
                }`}
              >
                <Button disabled={!eventId || isStarted}>
                  {eventGroups.length === 0
                    ? "Create First Group"
                    : "Create a" + " Group"}
                </Button>
              </Link>
              {!eventId && (
                <FormHint>Save event in order to create groups</FormHint>
              )}
            </ButtonWrapper>
          )}
          <RoundHeading>Rounds</RoundHeading>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {rounds.map((round, index) => (
                    <Draggable
                      key={round.id}
                      draggableId={round.id}
                      index={index}
                      isDragDisabled={isStarted || !editable}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <RoundListing disabled={isStarted || !editable}>
                            <DragWrapper>
                              <Drag size="small" />
                            </DragWrapper>
                            <Link
                              key={round.id}
                              to={`/create-edit-round?eventId=${eventId}&quizId=${prototypeQuizId}&roundNumber=${round.number}`}
                            >
                              Round {round.number}: {round.title}
                            </Link>
                          </RoundListing>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {editable && (
            <ButtonWrapper>
              <Link
                to={`/select-round-template?eventId=${eventId}&quizId=${prototypeQuizId}`}
              >
                <Button disabled={!eventId || isStarted}>
                  {rounds.length === 0 ? "Add First Round" : "Add a Round"}
                </Button>
              </Link>
              <span> or </span>
              <Link
                to={`/create-edit-round?eventId=${eventId}&quizId=${prototypeQuizId}`}
              >
                <Button disabled={!eventId || isStarted}>
                  {rounds.length === 0 ? "Enter First Round" : "Enter a Round"}
                </Button>
              </Link>
              {!eventId && (
                <FormHint>Save event in order to add rounds</FormHint>
              )}
            </ButtonWrapper>
          )}
          <RoundHeading>Tiebreaker</RoundHeading>
          {tiebreaker && (
            <TiebreakerListing>
              <Link
                to={`/create-edit-tiebreaker?eventId=${eventId}&quizId=${prototypeQuizId}&tiebreakerId=${tiebreaker.id}`}
              >
                {tiebreaker.text}
              </Link>
            </TiebreakerListing>
          )}
          {editable && (
            <ButtonWrapper>
              <Link
                to={`/select-tiebreaker-template?eventId=${eventId}&quizId=${prototypeQuizId}`}
              >
                <Button disabled={!eventId || isStarted}>
                  {tiebreaker ? "Change Tiebreaker" : "Add a Tiebreaker"}
                </Button>
              </Link>
              {!tiebreaker && (
                <>
                  <span> or </span>
                  <Link
                    to={`/create-edit-tiebreaker?eventId=${eventId}&quizId=${prototypeQuizId}`}
                  >
                    <Button disabled={!eventId || isStarted}>
                      Enter Tiebreaker
                    </Button>
                  </Link>
                </>
              )}
            </ButtonWrapper>
          )}
        </Form>
      </FormWrapper>
      <ActionBar>
        {editable && (
          <Button size="large" disabled={hosting} onClick={onHostEvent}>
            {hosting ? "Hosting" : "Host"}
          </Button>
        )}
        {!editable && (
          <Button
            size="large"
            disabled={hostingGroup}
            onClick={onHostEventGroup}
          >
            {hostingGroup ? "Hosting Group" : "Host Group"}
          </Button>
        )}
        <Button size="large" onClick={handleGetLinkClick}>
          Invite
        </Button>
        <Button
          size="large"
          disabled={!isChanged() || isSubmitting || isStarted}
          onClick={() => submitForm()}
        >
          {isSubmitting ? "Saving" : "Save"}
        </Button>
        <Link to="/event-list">
          <Button size="large">Close</Button>
        </Link>
        {isStarted && (
          <DangerButton size="large" onClick={handleResetClick}>
            Reset Event
          </DangerButton>
        )}
        {!isStarted && (
          <DangerButton
            disabled={!eventId}
            size="large"
            onClick={handleDeleteClick}
          >
            Delete
          </DangerButton>
        )}
      </ActionBar>
      {showGetLinkModal && (
        <GetLinkModal
          publicEventId={values.publicEventId}
          onClose={handleCloseGetLink}
        />
      )}
      {showResetModal && (
        <ResetEventModal
          publicEventId={values.publicEventId}
          onClose={handleCancelReset}
        />
      )}
      {showDeleteModal && (
        <Modal
          title="Delete Event"
          text="Delete this event and all the related rounds and questions?"
          buttons={
            <>
              <DangerButton
                size="large"
                disabled={isDeleting}
                onClick={handleConfirmDelete}
              >
                {isDeleting ? "Deleting" : "Delete"}
              </DangerButton>
              <Button size="large" onClick={handleCancelDelete}>
                Cancel
              </Button>
            </>
          }
        />
      )}
    </ContentBox>
  );
}

export default function CreateEditEvent({ eventId }) {
  const { data, refetch } = useQuery(GET_EVENT, {
    variables: {
      id: eventId,
    },
    skip: !eventId,
  });

  const [hosting, setHosting] = useState(false);
  const [hostingGroup, setHostingGroup] = useState(false);

  const [hostEvent] = useMutation(HOST_EVENT);
  const [hostEventGroup] = useMutation(HOST_EVENT_GROUP);
  const [saveEvent] = useMutation(SAVE_EVENT);
  const [reorderRounds] = useMutation(REORDER_ROUNDS);
  const [deleteEvent] = useMutation(DELETE_EVENT);

  if (eventId && !data) {
    return null;
  }

  const originalValues = (data && data.event) || {};

  const event = data && data.event;
  const publicEventId = event && event.publicId;
  const editable = event && event.editable;
  const ownerId = event && event.owner && event.owner.id;
  const ownerUsername = event && event.owner && event.owner.username;

  const initialValues = {
    eventName: (data && data.event && data.event.name) || "",
    publicEventId: (data && data.event && data.event.publicId) || "",
    ownerId: ownerId || "",
  };

  const validate = (values) => {
    const errors = {};
    if (!values.eventName) {
      errors.eventName = "You must enter a event name";
    }
    return errors;
  };

  const handleSubmit = async (values, { setStatus, setErrors }) => {
    try {
      const result = await saveEvent({
        variables: {
          id: eventId,
          name: values.eventName,
          ownerId: values.ownerId,
        },
      });
      const newEventId = result.data.saveEvent.event.id;
      await navigate(`/create-edit-event?eventId=${newEventId}`);
      await refetch({ id: newEventId });
    } catch (error) {
      Bugsnag.notify(error);

      if (
        error.graphQLErrors[0].message.startsWith(
          "No duplicate publicId values"
        )
      ) {
        setErrors({ publicEventId: "This event ID is already in use" });
      } else {
        setStatus("Unknown error, try again");
      }
    }
  };

  const handleHostEvent = async () => {
    setHosting(true);
    await hostEvent({
      variables: {
        publicEventId: data.event.publicId,
      },
    });
    await navigate("/preview-av/?next=event&selfie=true");
  };

  const handleHostEventGroup = async () => {
    setHostingGroup(true);
    await hostEventGroup({
      variables: {
        publicEventId: data.event.publicId,
      },
    });
    await navigate("/preview-av/?next=event&selfie=true");
  };

  const handleReorderRounds = async (roundId, newNumber) => {
    await reorderRounds({
      variables: {
        quizId: data.event.prototypeQuiz.id,
        roundId,
        newNumber,
      },
    });
    await refetch();
  };

  const handleDelete = async () => {
    await deleteEvent({
      variables: {
        id: eventId,
      },
    });
    await navigate("/event-list");
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleSubmit}
    >
      <EventEditor
        eventId={eventId}
        publicEventId={publicEventId}
        editable={editable || !eventId}
        ownerId={ownerId}
        ownerUsername={ownerUsername}
        eventGroups={(data && data.event && data.event.eventGroups) || []}
        prototypeQuizId={
          data &&
          data.event &&
          data.event.prototypeQuiz &&
          data.event.prototypeQuiz.id
        }
        rounds={
          (data &&
            data.event &&
            data.event.prototypeQuiz &&
            data.event.prototypeQuiz.rounds) ||
          []
        }
        tiebreaker={
          data &&
          data.event &&
          data.event.prototypeQuiz &&
          data.event.prototypeQuiz.tiebreaker
        }
        originalValues={originalValues}
        hosting={hosting}
        hostingGroup={hostingGroup}
        isStarted={data && data.event && data.event.whatToShow}
        onHostEvent={handleHostEvent}
        onHostEventGroup={handleHostEventGroup}
        onReorderRounds={handleReorderRounds}
        onDelete={handleDelete}
      />
    </Formik>
  );
}
