import styled from "styled-components";
import Button from "./Button";
import { BRAND, LIGHT_GREY_1 } from "../../colors";

const PrimaryButton = styled(Button)`
  background-color: ${BRAND};
  border-color: ${BRAND};
  color: ${LIGHT_GREY_1};

  :hover {
    box-shadow: 0 0 0 2px ${BRAND};
  }

  ${({ disabled }) =>
    disabled &&
    `
    :hover {
      box-shadow: none;
    }
  `}
`;

export default PrimaryButton;
