import React from "react";
import queryString from "query-string";
import CreateEditEvent from "../components/CreateEditEvent";
import PageWrapper from "../components/PageWrapper";

export default function CreateEditEventPage({ location }) {
  const { eventId } = queryString.parse(location.search);

  return (
    <PageWrapper>
      <CreateEditEvent key={eventId} eventId={eventId} />
    </PageWrapper>
  );
}
