import React from "react";
import Select from "./Select";
import { gql, useQuery } from "@apollo/client";

const ALL_USERS = gql`
  query {
    users: getAllUsers {
      id
      username
      email
    }
  }
`;

const UserSelect = ({ ...restOfProps }) => {
  /* If you are specifying a defaultValue, make sure it is in the form of an object with a value and a label */

  const { data } = useQuery(ALL_USERS);

  const users = data && data.users;

  const options = users
    ? users.map((user) => ({ value: user.id, label: user.username }))
    : [];

  return <Select options={options} {...restOfProps} />;
};

export default UserSelect;
